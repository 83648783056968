import React, { useEffect, useState } from 'react'
import { SurveyState } from 'store/type'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './SurveysScreen.module.scss'
import {
  SurveyRowModel,
  SurveysScreenComponentProps,
  SurveyStatusIcon
} from './SurveysScreen.model'
import { SideMenu } from 'components/SideMenu/SideMenu.component'
import { RADashboardMenu } from '../index.model'
import Box from '@mui/material/Box'
import { Button } from 'components/Button/Button.component'
import { IconType } from 'components/Icons/Icon.model'
import { BorderType } from 'components/Border/Border.model'
import { colors } from 'shared/theme/theme'
import classnames from 'classnames'
import {
  AGE,
  GENDER,
  REFUSAL_AGE,
  REFUSAL_GENDER
} from 'shared/constants/SurveyFields.d'
import { formatTime } from 'shared/utils/formatTime/formatTime'
import { Icon } from 'components/Icons/Icon.component'
import { ProjectSiteModel } from 'api/client.model'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { IconArrowUpWhite } from 'components/Icons/icons/Arrows/ArrowUpWhite'
import { IconArrowDownWhite } from 'components/Icons/icons/Arrows/ArrowDownWhite'
import { Dispatch } from 'redux'
import { syncSurveysAPI } from 'store/actionCreators'
import { Modal } from 'components/Modals/Modal/Modal.component'
import { Input } from 'components/Input/Input.component'
import { InputFormat } from 'components/Input/Input.model'
import { updateValidatedSLK } from 'store/reducer'
import { surveyAPI } from 'api/client'
import orderBy from 'lodash/orderBy'
import { saveAs } from 'file-saver'
import { DELETE_REFUSE_TAKE_PART } from 'shared/constants/messages/Messages.d'
import { mapProjectSiteTargetCollection } from 'store/mapper'
import { Alert } from 'components/Alert/Alert.component'
import { useColourPicker } from 'context/ColourContext'
import { filterSlkByProjectId } from 'shared/utils/slkUtils/slkUtils'
import { ProjectSiteSelect } from 'components/ProjectSiteSelect/ProjectSiteSelect.component'

const SurveysScreenComponent: React.FC<SurveysScreenComponentProps> = (
  props
) => {
  const navigate = useNavigate()
  const { handleSLKValidation } = surveyAPI()

  const {
    offline,
    serviceId,
    slkList,
    syncRounds,
    projects,
    projectId: selectedProjectId,
    siteId: selectedSiteId,
    surveyResponses
  } = props
  const dispatch: Dispatch<any> = useDispatch()

  const { secondaryColour } = useColourPicker()

  const [projectId, setProjectId] = useState<string>(selectedProjectId)
  const [siteId, setSiteId] = useState<string>(selectedSiteId)
  const [isSync, setIsSync] = useState<boolean>(false)
  const [updateSLKModalOpen, setUpdateSLKModalOpen] = useState<boolean>(false)
  const [SLKValue, setSLKValue] = useState<string>()
  const [errorSLKText, setErrorSLKText] = useState<string>()
  const [currentLocalIdentifier, setcurrentLocalIdentifier] = useState<string>()
  const [isValidFormat, setValidFormatStatus] = useState<boolean>(false)
  const [rows, setRows] = useState<SurveyRowModel[]>([])
  const [showAlert, setShowAlert] = useState(false)
  const responses = surveyResponses ? [...surveyResponses] : undefined

  const handleSyncAPI = () => {
    setIsSync(true)
    // Create a clean projectSiteTargetsRequest without the numbers of collections populated
    const projectSiteTargetsRequest = mapProjectSiteTargetCollection(projects)
    dispatch(
      syncSurveysAPI({
        serviceId,
        surveyResponses: responses,
        projectSiteTargets: projectSiteTargetsRequest
      })
    )
  }

  const handleInputValue = (value: string, error?: string) => {
    if (error) {
      setErrorSLKText(error)
      setValidFormatStatus(false)
    } else {
      setErrorSLKText(undefined)
      setSLKValue(value)
      setValidFormatStatus(true)
    }
  }

  const handleOpenSLKModal = (localIdentifer: string) => {
    setUpdateSLKModalOpen(true)
    setcurrentLocalIdentifier(localIdentifer)
  }

  const handleSubmitSLK = async () => {
    if (!isValidFormat) return
    const currentResponse = surveyResponses?.find(
      (x) => x.localIdentifier === currentLocalIdentifier
    )

    if (currentResponse) {
      try {
        const isSLKValid = await handleSLKValidation(SLKValue as string)
        if (isSLKValid) {
          dispatch(
            updateValidatedSLK({
              slk: SLKValue,
              localIdentifier: currentLocalIdentifier
            })
          )

          setUpdateSLKModalOpen(false)
        } else {
          setErrorSLKText('Invalid SLK')
        }
      } catch (error) {
        setErrorSLKText("Can't validate now. Please try again later.")
      }
    }
  }

  const handleMenuChange = (menu: string) => {
    navigate(`/ra-dashboard/${menu}`)
  }

  const renderRAName = (id: string) => {
    let site: ProjectSiteModel | undefined
    projects.some((project) => {
      if (project.id === projectId) {
        site = project.sites?.find((item) => item.id === siteId)
        return !!site
      }
    })

    if (site) {
      const ra = site.researchAssistants.find(
        (researchAssistant) => researchAssistant.id === id
      )
      if (ra && (ra.firstName || ra.lastName)) {
        return ra.firstName + ' ' + ra.lastName
      }
    }

    return id
  }

  const downloadSlkButton = () => {
    // Filter SLKs by projectId
    const filteredSlks = filterSlkByProjectId(slkList, projectId)

    if (!filteredSlks) {
      // Toggle alert visibility
      setShowAlert(true)
    } else {
      // Create the CSV content from slkList, each SLK on a new line
      const csvContent = filteredSlks.join('\n')
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

      // Use the projectId to name the file
      saveAs(blob, `${projectId}_slks.csv`)
      setShowAlert(false)
    }
  }

  const createRows = (): SurveyRowModel[] => {
    if (!responses || responses.length === 0) return []
    const responsesPerSite = responses.filter(
      (response) =>
        response.metadata?.projectId === projectId &&
        response.metadata.siteId === siteId /*&&
        response.metadata.deletionReason !== DELETE_REFUSE_TAKE_PART*/
    )

    return orderBy(
      responsesPerSite.map((item, index) => {
        const time = item.metadata ? item.metadata.endTime : undefined

        return {
          id: index,
          gender: item.responses
            ? item.responses[GENDER] || item.responses[REFUSAL_GENDER]
            : undefined,
          age: item.responses
            ? item.responses[AGE] || item.responses[REFUSAL_AGE]
            : undefined,
          time: time,
          ra:
            item.metadata && item.metadata.raId
              ? renderRAName(item.metadata.raId)
              : '',
          status: item.metadata ? item.metadata.status : undefined,
          localIdentifer: item.localIdentifier,
          slk: item.metadata ? item.metadata.slk : undefined
        }
      }),
      ['time', 'id'],
      ['desc', 'asc']
    )
  }

  const columns: GridColDef[] = [
    { field: 'slk', headerName: 'ID', width: 200, headerAlign: 'center' },
    {
      field: 'gender',
      headerName: 'Gender',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) =>
        params.value
          ? `${params.value.toString()[0].toUpperCase()}${params.value
              .toString()
              .substring(1)}`
          : ''
    },
    {
      field: 'age',
      headerName: 'Age',
      flex: 1,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'time',
      headerName: 'Date and Time',
      flex: 1,
      minWidth: 140,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => formatTime(params.value)
    },
    {
      field: 'ra',
      headerName: 'Research Assistant',
      flex: 1,
      minWidth: 190,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (!params.value) {
          return <></>
        }
        return (
          <div
            onClick={
              params.value === 'invalidSLK'
                ? () => {
                    handleOpenSLKModal(params.row.localIdentifer)
                  }
                : undefined
            }
          >
            <Icon
              className={classnames(styles.surveyStatusIcon, {
                [styles['surveyStatusIcon--loading']]: isSync
              })}
              icon={
                isSync
                  ? SurveyStatusIcon.forsync
                  : SurveyStatusIcon[
                      params.value as keyof typeof SurveyStatusIcon
                    ]
              }
              fill={params.value === 'complete' ? 'none' : undefined}
            />
          </div>
        )
      }
    }
  ]

  useEffect(() => {
    setIsSync(false)
    setRows(createRows())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncRounds, surveyResponses, projectId, siteId])

  return (
    <div className={styles.surveysscreen}>
      <SideMenu
        options={RADashboardMenu}
        value={RADashboardMenu[1].value}
        setValue={handleMenuChange}
      />
      <main className={styles['surveysscreen-content']}>
        <h1
          style={{
            visibility: 'hidden',
            position: 'absolute',
            pointerEvents: 'none'
          }}
        >
          Surveys
        </h1>
        <div className={styles['surveysscreen-row1']}>
          <Box component="form" className={styles['surveysscreen-row1-form']}>
            <ProjectSiteSelect
              selectedProjectId={projectId}
              selectedSiteId={siteId}
              setSelectedProjectId={setProjectId}
              setSelectedSiteId={setSiteId}
              projects={projects}
              additionalStylingClasses={styles['surveysscreen-select']}
              borderType={BorderType.Select2}
              style={{
                marginRight: 20,
                backgroundColor: colors.white
              }}
            />
          </Box>
          {offline ? (
            <Button
              className={styles['surveysscreen-row1-syncButton']}
              variation="primary"
              disabled
              icon={IconType.NotSync}
              style={{ backgroundColor: colors.white }}
            >
              Sync
            </Button>
          ) : (
            <Button
              className={classnames(styles['surveysscreen-row1-syncButton'], {
                [styles['surveysscreen-row1-syncButton--loading']]: isSync
              })}
              onClick={handleSyncAPI}
              icon={IconType.Sync}
              borderType={BorderType.Option1}
              style={{
                backgroundColor: isSync ? colors.greenSmoke : colors.white
              }}
            >
              {!isSync && 'Sync'}
            </Button>
          )}
        </div>
        <div className={styles['surveysscreen-row2']}>
          <Box
            className={styles['surveysscreen-row2-table']}
            sx={{ height: '100%', width: '100%' }}
          >
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: [{ field: 'time', sort: 'desc' }]
                }
              }}
              rows={rows}
              columns={columns}
              pageSize={rows.length > 100 ? 100 : rows.length}
              hideFooter
              disableColumnMenu
              headerHeight={55}
              components={{
                ColumnSortedAscendingIcon: IconArrowDownWhite,
                ColumnSortedDescendingIcon: IconArrowUpWhite
              }}
            />
          </Box>
        </div>
        <div className={styles['surveysscreen-row3']}>
          <div className={styles['surveysscreen-row3-icon']}>
            <Icon icon={IconType.Delete} />
            <span>Declined: Declined survey</span>
          </div>
          <div className={styles['surveysscreen-row3-icon']}>
            <Icon icon={IconType.Complete} fill="none" />
            <span>Complete: Survey is complete</span>
          </div>
          <div className={styles['surveysscreen-row3-icon']}>
            <Icon icon={IconType.ExistingSLK} />
            <span>Found existing SLK</span>
          </div>

          <div className={styles['surveysscreen-row3-icon']}>
            <Icon icon={IconType.Incomplete} />
            <span>
              Incomplete: Survey is incomplete and the person is coming back
            </span>
          </div>
          <div className={styles['surveysscreen-row3-icon']}>
            <Icon icon={IconType.Complete} />
            <span>Synced: Survey is complete and synced</span>
          </div>
          <div className={styles['surveysscreen-row3-icon']}>
            <Icon icon={IconType.InvalidSLK} />
            <span>Invalid SLK</span>
          </div>
          <div className={styles['surveysscreen-row3-icon']}>
            <Icon icon={IconType.Technical} />
            <span>Technical issues</span>
          </div>
          <div className={styles['surveysscreen-row3-icon']}>
            <Icon icon={IconType.Syncing} />
            <span>For syncing: Survey has not been synced to the server</span>
          </div>
          <div className={styles['surveysscreen-row3-icon']}>
            <Button
              className={styles['surveysscreen-row3-button']}
              onClick={downloadSlkButton}
              icon={IconType.ArrowDown}
              style={{ backgroundColor: colors.white }}
            >
              Download SLKs
            </Button>
          </div>
        </div>
      </main>

      {showAlert && (
        <Alert
          variation={'popup'}
          xOffset={'45%'}
          yOffset={'48%'}
          text="No SLK records available for download."
          icon={IconType.Technical}
          backgroundColor={secondaryColour}
          alertProps={{
            severity: 'error'
          }}
        />
      )}

      <Modal
        open={updateSLKModalOpen}
        customClose={async (cancel) =>
          cancel ? setUpdateSLKModalOpen(false) : handleSubmitSLK()
        }
        style={{ backgroundColor: colors.white }}
      >
        <div className={styles['surveysscreen-SLKModal']}>
          <h2>
            The SLK entered is invalid,
            <br /> please use a valid SLK
          </h2>
          <Input
            className={styles['surveysscreen-SLKModal-input']}
            inputOption={{
              label: "Participant's code",
              value: null,
              required: false,
              allowMultipleLines: false,
              inputFormat: InputFormat.slk
            }}
            style={{
              backgroundColor: colors.white,
              padding: '0 100px !important'
            }}
            setValue={(value, error) => handleInputValue(value, error)}
          ></Input>
          <p className={styles['surveysscreen-SLKModal-errortext']}>
            {errorSLKText}
          </p>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  offline: state.offline || false,
  serviceId: state.service?.id,
  slkList: state.slkList,
  syncRounds: state.syncRounds,
  projects: state.projects ?? [],
  projectId: state.selectedProjectSite?.projectId || '',
  siteId: state.selectedProjectSite?.siteId || '',
  surveyResponses: state.surveyResponses
})

export const SurveysScreen = connect(mapStateToProps)(SurveysScreenComponent)
